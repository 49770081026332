import { errorNotification, getUserAttribute } from '@/util';
import { httpClient } from '@/util/httpClient';

interface Params {
  page: number;
  per_page: number;
  title?: string;
  producer?: string;
  code?: string;
}

export const getMaterials = async (params: Params) => {
  try {
    const query = new URLSearchParams({
      page: params.page.toString(),
      per_page: params.per_page.toString(),
    });

    if (params.title) {
      query.append('title', params.title);
    }

    if (params.producer) {
      query.append('producer', params.producer);
    }

    if (params.code) {
      query.append('code', params.code);
    }

    const resp = await httpClient().get(`/materials/all?${query.toString()}`);

    return resp.data;
  } catch (error) {
    errorNotification('Failed to get materials data');
    console.error(error);
  }
};

'use client';

import React, { useMemo, useState } from 'react';
import { Divider, Flex, Progress, rem, Text } from '@mantine/core';
import cx from 'classnames';
import classes from './SimpleStatBlock.module.scss';
import { Button } from '../Button';
import useSWR from 'swr';
import { getMaterials } from '@/lib/getMaterials';
import { useDebouncedValue } from '@mantine/hooks';
import { useRouter } from 'next/navigation';


export const MaterialStatBlock = () => {
  const [filters, setFilters] = useState({ title: '', producer: '', code: '' });
  const [debouncedFilters] = useDebouncedValue(filters, 300);
  const router = useRouter();

  const showMaterials = () => {
    router.push('/materials');
  };

  const addMaterial = () => {
    router.push('/materials/create');
  };

  const params = {
    page: 1,
    per_page: 9,
    ...debouncedFilters
  };

  const { data: MaterialList, isLoading: tableLoading, mutate } = useSWR(
    ['/materials/all', debouncedFilters],
    () => getMaterials(params)
  );

  const materialsSummary = useMemo(() => {
    return MaterialList;
  }, [MaterialList]);

  return (
    <Flex w={'49%'} direction={'column'}>
      <Flex direction={'column'} w='100%' bg={'#F7F9FB'} mb={rem(20)} h={rem(200)} style={{borderRadius: 16}} p={rem(20)}>
        <Flex justify={'space-between'} align={'center'} h={'50%'}>
          <Text fz={rem(18)} fw={600}>Materiāli</Text>
          <Button size="compact-md" onClick={() => addMaterial()}>
            <Text fz={rem(14)}>
              Pievienot jaunu
            </Text>
          </Button>
        </Flex>
        <Flex direction={'row'} h={'50%'} w={rem(300)} justify={'space-between'} align={'center'}>
          <Flex direction={'column'}>
            <Text fz={rem(14)} fw={400}>
              Kopā
            </Text>
            <Text fz={rem(18)} fw={600}>
              {materialsSummary && materialsSummary.summary && materialsSummary.summary.Total || ""}
            </Text>
          </Flex>
          <Flex h={rem(40)}>
            <Divider size="md" orientation="vertical" />
          </Flex>
          <Flex direction={'column'}>
            <Text fz={rem(12)} fw={400}>Pieejami</Text>
            <Progress.Root size='xl' w={rem(200)} h={rem(30)}>
              <Progress.Section value={97} color="#C6C7F8">
                <Progress.Label>97%</Progress.Label>
              </Progress.Section>
            </Progress.Root>
          </Flex>
        </Flex>
      </Flex>
      <Flex w={'100%'} bg={'#F7F9FB'} h={rem(500)} style={{borderRadius: 16}}  p={rem(20)}>
        <Flex direction={'column'} w={'100%'}>
          <Flex justify={'space-between'} align={'center'}>
            <Text fz={rem(14)} fw={600}>
              Pēdējie pievienotie
            </Text>
            <Button variant='transparent' c={'grey'} onClick={() => showMaterials()}>Skatīt pilno sarakstu</Button>
          </Flex>
            {materialsSummary && materialsSummary.results && materialsSummary.results.map((item: any) => (
              <Flex h={rem(36)} direction={'column'} mb={rem(10)}>
                  <Text fz={rem(12)} fw={400}>{item.title}</Text>
                  <Text fz={rem(12)} fw={400} c={'grey'}>{item.producer}</Text>
              </Flex>
            ))}
          </Flex>
         
      </Flex>
    </Flex>
  );
};

'use client';

import dayjs from 'dayjs';
import {
  Flex,
  rem,
  NumberFormatter,
  Button,
  FileButton,
  ActionIcon,
  Group,
  Text,
} from '@mantine/core';
import useSWR from 'swr';
import Image from 'next/image';
import { useTranslation } from '@/hooks';
import { useRouter } from 'next/navigation';
import classes from './Home.module.scss';
import { SimpleStatBlock, PageHeader } from '@/components';
import { UserTraffic } from '@/components/UserTraffic';
import { uploadDocument } from '@/lib/uploadDocument';
import { useMemo, useState } from 'react';
import { Archive } from '@phosphor-icons/react/dist/ssr';
import { getObjects } from '@/lib/getObjects';
import { MaterialStatBlock } from '@/components/MaterialStatsBlock';
import { SystemStatBlock } from '@/components/SystemStatsBlock';

export const Home = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const params = {
    page: 1,
    per_page: 3,
  };

  const {
    data: ObjectList,
    isLoading: tableLoading,
    mutate,
  } = useSWR(`/objects/all`, () => getObjects(params));

  const objects = useMemo(() => {
    if (!ObjectList) return [];
   
    return ObjectList.results;
  }, [ObjectList]);

  return (
    <Flex direction="column">
      <div
        className={classes.Heading}
        style={{ flexDirection: 'row', alignItems: 'center' }}
      >
        <PageHeader title={t('home')} />
      </div>
      <Flex w="100%" gap={rem(24)} mb={rem(24)}>
        <div className={classes.grid}>
          {objects.map((item: any) => (
            <SimpleStatBlock
              title={item.title}
              rows={[
                {
                  name: '',
                  value: (
                    // <NumberFormatter
                    //   prefix="€"
                    //   value={239110}
                    //   thousandSeparator
                    // />
                    <Text fw={600} fz={rem(20)}>{item.case_number}</Text>
                  ),
                  date: item.object_street,
                },
              ]}
              light
            />
          ))}
          <Button
            w="100%"
            h={rem(138)}
            bg="#000000"
            fz={rem(18)}
            fw={400}
            radius={rem(15)}
            onClick={() => router.push('/objects')}
          >
            Pievienot jaunu objektu +
          </Button>
        </div>
      </Flex>
      <Flex direction={'row'} justify={'space-between'} mb={rem(20)}>
        <MaterialStatBlock/>
        <SystemStatBlock/>
      </Flex>
      <div>
        <UserTraffic data={[1, 2, 3, 3, 3, 3, 3, 3]} />
      </div>
    </Flex>
  );
};

import { errorNotification, getUserAttribute } from '@/util';
import { httpClient } from '@/util/httpClient';

interface Params {
  page: number;
  per_page: number;
}

export const getObjects = async (params: Params) => {
  try {
    const resp = await httpClient().get(
      `/objects/all?page=${params.page}&per_page=${params.per_page}`,
    );

    return resp.data;
  } catch (error) {
    errorNotification('Failed to get objects');
    console.error(error);
  }
};
